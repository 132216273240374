<template>
  <div class="">
    <center-title title="智能化采购分析"></center-title>
    <el-form :inline="true" :model="form" class="demo-form-inline">
      <div class="row-between">
        <div class="row">
          <el-form-item>
            <el-select v-model="form.dname" filterable placeholder="部门">
              <el-option label="全部" value="0"></el-option>
              <el-option
                v-for="item in dname"
                :key="item.id"
                :label="item.dname"
                :value="item.dname"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select v-model="form.trueuser" filterable placeholder="采购人">
              <el-option label="全部" value="0"></el-option>
              <el-option
                v-for="item in trueuser"
                :key="item.id"
                :label="item.true_name"
                :value="item.true_name"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-date-picker
              v-model="form.date"
              value-format="yyyy-MM-dd"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" class="whtie submit" @click="resetList(1)"
            >筛选</el-button
          >
        </el-form-item>
      </div>
    </el-form>
    <el-table :data="purchaseAnalysis">
      <el-table-column
        align="center"
        prop="部门名称"
        label="部门名称"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="采购人"
        label="采购人"
        width="150px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="商品数量"
        label="采购数量"
        width="100px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="goods_price"
        label="采购金额"
        width="100px"
      >
        <template slot-scope="scope">
          <make-priceformat
            :price="scope.row.goods_price"
            :subscriptSize="14"
            :firstSize="14"
            :secondSize="14"
            color="#333333"
          ></make-priceformat>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="订单数量"
        label="订单数量"
        width="100px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="订单完成数量"
        label="订单完成数量"
        width="100px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="订单完成率"
        label="订单完成率"
        width="100px"
      ></el-table-column>
      <!-- <el-table-column align="center" prop="平均单次采购金额" label="周期" width="100px"></el-table-column> -->
      <el-table-column
        align="center"
        prop="品类数量"
        label="采购品类数量"
        width="100px"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="剩余额度"
        label="剩余额度"
        width="100px"
      ></el-table-column>
    </el-table>

    <div class="row-center pagination mt30">
      <make-pagination
        @resetList="resetList"
        :orderObject="orderObject"
      ></make-pagination>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1080);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      likename: [],
      trueuser: [],
      page_no: 1,
      page_size: 10,
      purchaseAnalysis: [],
      dialogVisible: false,
      uploadDialogVisible: false,
      download: process.env.VUE_APP_DOWNLOAD_URL,
      form: {
        date: [],
        start_time: "",
        end_time: "",
      },
      orderObject: {},
      auditBox: {
        id: "",
        type: 1,
        reject_reason: "",
      },
      uploadPay: "",
    };
  },
  computed: {},
  watch: {},
  methods: {
    successUpload(res) {
      let { code, data } = res;
      this.uploadPay = res.data.uri;
    },
    onSubmit() {},
    resetList(e) {
      this.page_no = e;
      this.getpurchaseAnalysis();
    },
    /**
     * @description 获取列表
     * @returns void
     */
    getpurchaseAnalysis() {
      this.$api
        .getpurchaseAnalysisApi({
          type: this.queryType,
          page_no: this.page_no,
          page_size: this.page_size,
          ...this.form,
          start_time: this.form.date && this.form?.date[0],
          end_time: this.form.date && this.form?.date[1],
        })
        .then((res) => {
          this.purchaseAnalysis = res.data.order;
          this.trueuser = res.data.like;
          this.dname = res.data.likename;
          let {
            order,
            like,
            likename,
            count,
            page_size: size,
            page_no: page,
          } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            order,
            count,
            like,
            likename,
          };
        });
    },
    setRmOperate() {
      this.$api.setRmOperateApi(this.auditBox).then((res) => {
        if (res.code == 1) {
          this.resetList(1);
          this.dialogVisible = false;
        }
      });
    },
    resetList(e) {
      this.page_no = e;
      this.getpurchaseAnalysis();
    },
  },
  created() {
    this.getpurchaseAnalysis();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
/deep/ .el-form {
  .el-form-item__content,
  .el-input__inner {
    // height: 32px;
    // line-height: 32px;
    // width: 240px;
    border-radius: 0;
  }

  .el-input__icon {
    line-height: 32px;
  }

  .el-date-editor .el-range-separator {
    width: auto;
  }
}

.el-upload {
  // border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  button {
    border-radius: 5px;
    padding: 10px 20px;
  }
}

.el-upload:hover {
  border-color: inherit;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

/deep/ .el-table {
  border: $-solid-border;
  border-bottom: none;

  th {
    background-color: $-color-center;
    font-size: 16px;
    font-weight: normal;
    color: $-color-lighter;
  }

  td {
    &:nth-child(2) {
      .cell {
        padding: 0 10px;
      }
    }

    .cell {
      padding: 0px 20px;
      line-height: 1.3;
      word-break: break-all;
    }
  }

  th,
  td {
    border-right: $-solid-border;

    &:last-child {
      border-right: none;
    }
  }
}
</style>
